<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        :model="form"
        :rules="rules"
        ref="fawen"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <table class="formtable">
          <yanjiuyuan
            v-if="form.type == '省教科院发文卡'"
            :formData="form"
            @callback="dataBack"
          />
          <guihuaban
            v-if="form.type == '省规划办发文卡'"
            :formData="form"
            @callback="dataBack"
          />
          <jiaoyuxuehui
            v-if="form.type == '省教育学会发文卡'"
            :formData="form"
            @callback="dataBack"
          />
          <tr>
            <td style="width: 120px"><span style="color: red">*</span>标题</td>
            <td style="text-align: left" colspan="5">
              <a-form-model-item prop="title" style="margin-bottom: 0px">
                <a-input placeholder="请输入" v-model="form.title" />
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td rowspan="5"><span style="color: red">*</span>主送单位</td>
            <td style="text-align: left" rowspan="5" colspan="2">
              <a-form-model-item prop="dispatchOrg" style="margin-bottom: 0px">
                <a-input placeholder="请输入" v-model="form.dispatchOrg" />
              </a-form-model-item>
            </td>
            <td><span style="color: red">*</span>抄报</td>
            <td style="text-align: left" colspan="2">
              <a-form-model-item
                prop="otherExtension.chaobao"
                style="margin-bottom: 0px"
              >
                <a-input
                  placeholder="请输入"
                  v-model="form.otherExtension.chaobao"
                />
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td><span style="color: red">*</span>报送</td>
            <td style="text-align: left" colspan="2">
              <a-form-model-item
                prop="otherExtension.baosong"
                style="margin-bottom: 0px"
              >
                <a-input
                  placeholder="请输入"
                  v-model="form.otherExtension.baosong"
                />
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td rowspan="3"><span style="color: red">*</span>公开选项</td>
            <td style="text-align: left" colspan="2">
              <a-checkbox
                :checked="gongkai.item1"
                @change="onCheckboxChange($event, 1)"
                >主动公开</a-checkbox
              >
            </td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="2">
              <a-checkbox
                :checked="gongkai.item2"
                @change="onCheckboxChange($event, 2)"
                >依申请公开</a-checkbox
              >
            </td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="2">
              <a-checkbox
                :checked="gongkai.item3"
                @change="onCheckboxChange($event, 3)"
                >不予公开</a-checkbox
              >
            </td>
          </tr>
          <tr>
            <td>附件</td>
            <td colspan="5" style="text-align: left">
              <a-upload
                :action="uploadFileUrl"
                :fileList="fileList"
                @preview="showWpsRead"
                @change="handleFileChange"
                :remove="() => upLoadRemove"
                style="margin-bottom: 0px"
              >
                <div v-if="this.form.status == 0 || this.form.status == 3">
                  <a-button><a-icon type="upload" />上传文件</a-button>
                </div>
              </a-upload>
            </td>
          </tr>
          <tr>
            <td><span style="color: red">*</span>发文编号</td>
            <td style="width: 350px">
              <documentnumber :ty="form.type" ref="number" :v="form.number" />
            </td>
            <td><span style="color: red">*</span>时间</td>
            <td style="width: 200px">
              <a-form-model-item prop="time" style="margin-bottom: 0px">
                <a-date-picker v-model="form.time" />
              </a-form-model-item>
            </td>
            <td><span style="color: red">*</span>印数</td>
            <td style="width: 200px">
              <a-form-model-item
                prop="otherExtension.yinshu"
                style="margin-bottom: 0px"
              >
                <a-input-number
                  v-model="form.otherExtension.yinshu"
                ></a-input-number>
              </a-form-model-item>
            </td>
          </tr>
        </table>
      </a-form-model>
    </div>
    <a-button
      type="default"
      v-if="this.form.status == 2"
      @click="showWps"
      class="cancelBtn"
      >打印</a-button
    >
    <a-button
      type="primary"
      v-if="form.status == 2 && allowFinish"
      style="margin-right: 5px"
      @click="finishTask()"
      >办理</a-button
    >
    <a-modal
      title="流转"
      v-model="flowVisible"
      width="70%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <flow :info="form" @callback="auditdata" />
    </a-modal>
  </a-spin>
</template>
<script>
import documentnumber from "../components/documentnumber.vue";
import yanjiuyuan from "./yanjiuyuan.vue";
import guihuaban from "./guihuaban.vue";
import jiaoyuxuehui from "./jiaoyuxuehui.vue";
import request from "@/request";
import documentdispatchApi from "@/api/work/documentdispatch";
import flow from "./flow.vue";
import pinyin from "js-pinyin/index";
import workflowApi from "@/api/workflow";
export default {
  name: "fawen",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    detailMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        title: "",
        dispatchOrg: "",
        status: 0,
        otherExtension: {
          chaobao: "",
          baosong: "",
        },
        type: "",
        time: null,
      },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        dispatchOrg: [
          { required: true, message: "请输入主送单位", trigger: "blur" },
        ],
        otherExtension: {
          chaobao: [{ required: true, message: "请输入抄报", trigger: "blur" }],
          baosong: [{ required: true, message: "请输入报送", trigger: "blur" }],
          yinshu: [{ required: true, message: "请输入印数", trigger: "blur" }],
        },
        time: [{ required: true, message: "请选择时间", trigger: "change" }],
      },
      taskId: "",
      allowFinish: false,
      flowVisible: false,
      gongkai: {
        item1: false,
        item2: false,
        item3: false,
      },
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      loading: false,
      fileList: [],
      uploadFileUrl: "",
      upLoadRemove: false,
      isloading: false,
      loadingTip: "加载中...",
    };
  },

  components: {
    documentnumber,
    yanjiuyuan,
    guihuaban,
    jiaoyuxuehui,
    flow,
  },
  mounted() {
    localStorage.removeItem("publicFileId");
    this.uploadFileUrl = request.getUploadUrl("dispatch");
    if (this.info.id) {
      this.getData();
    } else {
      this.form.type = this.info.type;
      this.form.status = 0;
      this.upLoadRemove = true;
      this.form.otherExtension = {};
    }
  },
  methods: {
    dataBack(calldata, getNumber) {
      if (getNumber) {
        this.form.number = this.$refs.number.getNumber();
      } else {
        if (calldata != null) {
          this.form.userInfo = calldata.userInfo;
          this.form.extensionSetting = calldata.extensionSettingData;
          this.$refs.fawen.validate((valid) => {
            if (valid) {
              this.flowVisible = true;
            }
          });
        } else if (this.form.status == 1) {
          this.getData();
          this.$emit("callback", false);
        }
      }
    },
    auditdata(data) {
      this.flowVisible = false;
      if (data) {
        this.form.otherExtension.remark = data.remark;
        this.handleSubmit(1);
      }
    },
    onCheckboxChange(e, v) {
      this.form.otherExtension.gongkai = v;
      if (e.target.checked) {
        if (v == 1) {
          this.gongkai.item1 = true;
          this.gongkai.item2 = false;
          this.gongkai.item3 = false;
        } else if (v == 2) {
          this.gongkai.item1 = false;
          this.gongkai.item2 = true;
          this.gongkai.item3 = false;
        } else if (v == 3) {
          this.gongkai.item1 = false;
          this.gongkai.item2 = false;
          this.gongkai.item3 = true;
        }
      }
    },

    handleFileChange(fileInfo) {
      this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "error") {
        this.$message.error("上传失败");
      }
    },
    getData() {
      this.loading = true;
      documentdispatchApi.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.time = this.$moment(res.data.time, "YYYY-MM-DD");
          if (res.data.extensionSetting) {
            res.data.extensionSetting = JSON.parse(res.data.extensionSetting);
            res.data.hegao =
              res.data.extensionSetting[
                "node_" + res.data.type + "-核稿"
              ].targetUsers;
            res.data.hegaoName =
              res.data.extensionSetting[
                "node_" + res.data.type + "-核稿"
              ].targetUserNames;
            if (
              res.data.extensionSetting["node_" + res.data.type + "-会签意见"]
            ) {
              res.data.huiqian =
                res.data.extensionSetting[
                  "node_" + res.data.type + "-会签意见"
                ].targetUsers;
              res.data.huiqianName =
                res.data.extensionSetting[
                  "node_" + res.data.type + "-会签意见"
                ].targetUserNames;
            }
          }
          if (res.data.otherExtension) {
            res.data.otherExtension = JSON.parse(res.data.otherExtension);
          } else {
            res.data.otherExtension = {};
          }
          if (res.data.otherExtension.gongkai) {
            if (res.data.otherExtension.gongkai == 1) {
              this.gongkai.item1 = true;
            } else if (res.data.otherExtension.gongkai == 2) {
              this.gongkai.item2 = true;
            } else if (res.data.otherExtension.gongkai == 3) {
              this.gongkai.item3 = true;
            }
          }
          if (res.data.attachment) {
            var d = JSON.parse(res.data.attachment);
            var files = [];
            for (var i = 1; i <= d.length; ++i) {
              var a = d[i - 1];
              files.push({
                uid: i,
                name: a.title,
                status: "done",
                response: a,
                url: a.url,
              });
            }

            this.fileList = files;
          }
          this.getTask(res.data.processId);
          if (res.data.status == 0 || res.data.status == 3) {
            this.upLoadRemove = true;
          }
          this.form = res.data;
          if (
            this.info &&
            this.info.taskId &&
            this.info.taskId != this.form.taskId
          ) {
            this.form.taskId = this.info.taskId;
          }
        }
      });
    },
    handleSubmit(status) {
      this.form.extensionSetting = JSON.stringify(this.form.extensionSetting);
      this.$refs.fawen.validate((valid) => {
        if (valid) {
          if (!this.form.otherExtension.gongkai) {
            this.$message.error("请选择公开方式");
            return;
          }
          let formData = { ...this.form, status };
          if (formData.otherExtension) {
            formData.otherExtension = JSON.stringify(formData.otherExtension);
          }
          formData.time = formData.time
            ? formData.time.format("YYYY-MM-DD")
            : "";
          formData.number = this.$refs.number.getNumber();
          let reg = /(.+)-(.+)-(.+)/;
          if (!reg.test(formData.number)) {
            this.$message.error("请输入发文编号");
            return;
          }
          delete formData.auditRecords;

          if (this.fileList.length > 0) {
            var file = [];
            this.fileList.forEach((f) => {
              file.push({
                title: f.response.title,
                url: f.response.url,
              });
            });
            formData.attachment = JSON.stringify(file);
          }
          this.loading = true;
          this.loadingTip = "保存中...";
          if (this.info) {
            formData.id = this.info.id;
          }
          //添加
          documentdispatchApi
            .addOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == this.$commons.RespCode.success) {
                this.$message.success(res.errorMsg);
                this.$emit("callback", true);
              } else {
                this.$message.error(res.errorMsg);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      this.$emit("callback", false);
    },
    async finishTask() {
      if (this.taskId == "" || this.taskId == null) {
        this.$message.error("已办理");
        return;
      }
      this.worktaskloading = true;
      var res = await workflowApi.finishTask(this.taskId);
      this.worktaskloading = false;
      if (res.errorCode === this.$commons.RespCode.success) {
        this.$message.success("操作成功");
        this.getData();
      } else {
        this.$message.error(res.errorMsg);
      }
    },
    getTask(id) {
      this.loading = true;
      this.taskId = "";
      this.allowFinish = false;
      workflowApi.getTask(id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          if (res.data != null && res.data != "" && res.data.length > 0) {
            this.taskId = res.data[0].id;
            this.allowFinish = true;
            this.$forceUpdate();
          }
        }
      });
    },
    showWpsRead(file) {
      let type = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        type.toLowerCase().indexOf("doc") <= -1 &&
        type.toLowerCase().indexOf("xls") <= -1 &&
        type.toLowerCase().indexOf("ppt") <= -1 &&
        type.toLowerCase().indexOf("pdf") <= -1
      ) {
        window.open(file.response.url, "_blank");
      } else {
        let fileId =
          "file" +
          file.url
            .substring(file.url.lastIndexOf("/") + 1, file.url.lastIndexOf("."))
            .replaceAll("-", "");
        let url = file.url.substring(file.url.indexOf("oabucket"));
        let type = file.url.substring(file.url.lastIndexOf(".") + 1);
        localStorage.removeItem("fileRrl");
        localStorage.setItem("fileRrl", url);
        localStorage.removeItem("fileName");
        localStorage.setItem("fileName", file.name);
        localStorage.removeItem("fileId");
        localStorage.setItem("fileId", fileId);
        localStorage.removeItem("fileTpye");
        localStorage.setItem("fileTpye", type);
        const jump = this.$router.resolve({
          name: "wpsRead",
          query: {
            //要传的参数
            fileName: file.name,
          },
        });

        window.open(jump.href, "_blank");
      }
    },
    showWps() {
      let templateId;
      if (this.form.type == "省规划办发文卡") {
        templateId = "fawenka_GuiHuaBan";
      }
      if (this.form.type == "省教科院发文卡") templateId = "fawenka_JiaoKeYuan";
      if (this.form.type == "省教育学会发文卡")
        templateId = "fawenka_JiaYuXueHui";
      let _number = this.form.number;
      let innerData = {
        id: this.form.id,
        status: this.form.status,
        print: this.form.print,
        name: _number.split("-")[0],
        year: _number.split("-")[1],
        number: _number.split("-")[2],
      };
      innerData.remark = this.form.otherExtension.remark;
      innerData.title = this.form.title;
      innerData.dispatchOrg = this.form.dispatchOrg;
      innerData.time = this.form.time.format("YYYY年MM月DD日");
      innerData.creatorName = this.form.creatorName;
      innerData["otherExtension.chaobao"] = this.form.otherExtension.chaobao;
      innerData["otherExtension.baosong"] = this.form.otherExtension.baosong;
      innerData["otherExtension.yinshu"] =
        this.form.otherExtension.yinshu.toString();
      innerData["otherExtension.gongkai=1"] =
        this.form.otherExtension.gongkai == 1 ? "是" : "";
      innerData["otherExtension.gongkai=2"] =
        this.form.otherExtension.gongkai == 2 ? "是" : "";
      innerData["otherExtension.gongkai=3"] =
        this.form.otherExtension.gongkai == 3 ? "是" : "";
      this.form.auditRecords.forEach((a) => {
        let key =
          "taskKey." + pinyin.getFullChars(a.taskKey.split("-")[1].toString());
        if (a.taskPrintKey != null) {
          key = "taskKey." + a.taskPrintKey;
        }
        if (innerData[key] == null) {
          innerData[key] = [];
        }
        let task = {
          auditRemark: a.auditRemark,
          pic:
            "http://localhost:9000/" +
            a.auditUserSignPicture.substring(
              a.auditUserSignPicture.indexOf("uas")
            ),
          name: a.auditUserName,
          time: this.$moment(a.time, "YYYY-MM-DD").format("YYYY年MM月DD日"),
        };
        innerData[key].push(task);
      });
      localStorage.setItem("innerData", JSON.stringify(innerData));
      localStorage.removeItem("publicFileId");
      const jump = this.$router.resolve({
        name: "wpsedit",
        query: {
          //要传的参数
          templateId: templateId,
          fileType: "public",
          openType: "create",
          fileName: "省教科院发文卡_" + _number.replaceAll("-", "_"),
          printType: "custom_fawen",
        },
      });

      window.open(jump.href, "_blank");
    },
  },
};
</script>
