<template>
  <div>
    <a-select v-model="number.han" style="width: 35%" @change="numberChanged">
      <a-select-option
        v-for="item in items"
        :key="item.id"
        :value="item.itemValue"
      >
        {{ item.itemName }}
      </a-select-option>
    </a-select>
    <a-select v-model="number.year" style="width: 25%" @change="yearChanged">
      <a-select-option v-for="item in years" :key="item" :value="item">
        {{ item }}
      </a-select-option>
    </a-select>
    <a-input v-model="number.no" style="width: 20%"></a-input>
    <span v-if="ty == 'jy'" style="width: 10%">
      第 <a-input v-model="number.qi" style="width: 10%"></a-input> 期</span
    >
  </div>
</template>
<script>
import dictionaryItemApi from "@/api/system/dictionaryItem";
import documentdispatchApi from "@/api/work/documentdispatch";
export default {
  name: "documentnumber",
  data() {
    return {
      items: [],
      years: [],
      number: {
        han: "",
        year: "",
        no: "",
        qi: "",
      },
    };
  },
  props: {
    v: String,
    ty: String,
  },
  mounted() {
    this.getDic();
    var d = new Date();
    var y = d.getFullYear();
    for (var i = 0; i < 5; ++i) {
      this.years.push(y - i);
    }
  },
  watch: {
    v() {
      this.setValue();
    },
    ty() {
      this.setTy();
    },
  },
  methods: {
    setValue() {
      if (this.v) {
        var arr = this.v.split("-");
        this.number.han = arr[0];
        this.number.year = arr[1];
        this.number.no = arr[2];
        if (arr[3] != null) this.number.qi = arr[3];
        this.$forceUpdate();
      }
    },
    setTy() {
      if (this.ty == "省教科院发文卡")
        dictionaryItemApi
          .getItemByDictionaryName("省教科院发文卡")
          .then((res) => {
            if (res.errorCode == this.$commons.RespCode.success) {
              this.items = res.data;
              this.setValue();
            }
          });
      if (this.ty == "省规划办发文卡")
        dictionaryItemApi
          .getItemByDictionaryName("省规划办发文卡")
          .then((res) => {
            if (res.errorCode == this.$commons.RespCode.success) {
              this.items = res.data;
              this.setValue();
            }
          });
      if (this.ty == "省教育学会发文卡")
        dictionaryItemApi
          .getItemByDictionaryName("省教育学会发文卡")
          .then((res) => {
            if (res.errorCode == this.$commons.RespCode.success) {
              this.items = res.data;
              this.setValue();
            }
          });
    },
    getDic() {
      if (this.ty == "tz")
        dictionaryItemApi.getItemByDictionaryName("发文编号").then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.items = res.data;
            this.setValue();
          }
        });
      if (this.ty == "jy")
        dictionaryItemApi.getItemByDictionaryName("会议纪要").then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.items = res.data;
            this.setValue();
          }
        });
    },
    getNumber() {
      if (this.ty == "jy")
        return (
          this.number.han +
          "-" +
          this.number.year +
          "-" +
          this.number.no +
          "-" +
          this.number.qi
        );
      else
        return this.number.han + "-" + this.number.year + "-" + this.number.no;
    },
    numberChanged(v) {
      this.$emit("callback", v);
    },
    yearChanged(v) {
      let documentNumber = {};
      documentNumber.year = v;
      documentNumber.han = this.number.han;
      documentNumber.no = 0;
      documentdispatchApi.getDocumentNumber(documentNumber).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          let stringValue = res.data.no.toString();
          this.number.no =
            stringValue.length >= 3
              ? stringValue
              : "0".repeat(3 - stringValue.length) + stringValue;
        }
      });
    },
  },
};
</script>